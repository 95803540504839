<template>
  <div class="container">
    <uiAlert ref="alert"></uiAlert>
    <uiTopPageSection
      :src="backgroundImage"
      :bgPosition="{ x: '62%', y: '60%' }"
    >
      <div class="top-content-section">
        <uiAnimatedTexts
          :texts="[
            'the safety for your life',
            'best american ballistic defense',
          ]"
        />
        <h1 class="text_super_big">DEMIDOV ARMOR</h1>
      </div>
    </uiTopPageSection>
    <section class="home-info1 section">
      <h2 class="text_medium b_margin_small">{{ $t("home.section1.p1") }}</h2>
      <p class="text_small text_line15">{{ $t("home.section1.p2") }}</p>
      <ui-link-button
        class="link-button"
        :page="this.$i18n.locale + '/about_armor'"
      >
        {{ $t("home.linkButtons.b1") }}
      </ui-link-button>
    </section>
    <section class="home-info2 dark">
      <div class="section" v-scrollanimate="'bottom'">
        <h2 class="text_medium b_margin_small">{{ $t("home.section2.p1") }}</h2>
        <p class="text_small text_line15">{{ $t("home.section2.p2") }}</p>
        <ui-link-button
          class="link-button"
          text-color="white"
          :page="this.$i18n.locale + '/demidov_armor'"
        >
          {{ $t("home.linkButtons.b1") }}
        </ui-link-button>
      </div>
      <div class="img-zhupan">
        <div class="img-overgroud"></div>
      </div>
    </section>
    <section class="videos">
      <div class="videos-wrapper">
        <div class="video-wrapper" ref="video_1">
          <div class="video-header  text_medium">About Armor</div>
          <div class='embed-container'><iframe src='https://www.youtube.com/embed/ZOngiu0Brx0?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&color=ffbe4f' frameborder='0' allowfullscreen></iframe></div>
        </div>
        <div class="video-wrapper" ref="video_2">
          <div class="video-header text_medium">ArGhost</div>
          <div class='embed-container'><iframe src='https://www.youtube.com/embed/uENnzweB260?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&color=ffbe4f' frameborder='0' allowfullscreen></iframe></div>
        </div>
        <div class="video-wrapper" ref="video_3">
          <div class="video-header text_medium">Ballistic</div>
          <div class='embed-container'><iframe src='https://www.youtube.com/embed/p60uREqjGcw?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&color=ffbe4f' frameborder='0' allowfullscreen></iframe></div>
        </div>
        <div class="video-wrapper" ref="video_4">
          <div class="video-header text_medium">Transfer Bag</div>
          <div class='embed-container'><iframe src='https://www.youtube.com/embed/BfgsD_aSo3E?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&color=ffbe4f' frameborder='0' allowfullscreen></iframe></div>
        </div>
      </div>
    </section>
    <section class="home-info3 dark v_padding_medium">
      <h2 class="text_medium padding_small text_bold">
        {{ $t("home.section3.p1") }}
      </h2>
      <div class="product-items">
        <div class="product-item">
          <img class="responsive-img" src="/home/armor.jpg" />
          <p v-scrollanimate="'bottom'" class="text_medium text_uppercase">
            {{ $t("home.section3.p2") }}
          </p>
        </div>
        <div class="product-item">
          <img class="responsive-img" src="/home/luxury.jpg" />
          <p v-scrollanimate="'bottom'" class="text_medium text_uppercase">
            {{ $t("home.section3.p3") }}
          </p>
        </div>
        <div class="product-item">
          <img class="responsive-img" src="/home/torso.jpg" />
          <p v-scrollanimate="'bottom'" class="text_medium text_uppercase">
            {{ $t("home.section3.p4") }}
          </p>
        </div>
      </div>
      <div class="product-items">
        <div class="product-item">
          <img class="responsive-img" src="/home/women.jpg" />
          <p v-scrollanimate="'bottom'" class="text_medium text_uppercase">
            {{ $t("home.section3.p5") }}
          </p>
        </div>
        <div class="product-item">
          <img class="responsive-img" src="/home/carrying.jpg" />
          <p v-scrollanimate="'bottom'" class="text_medium text_uppercase">
            {{ $t("home.section3.p6") }}
          </p>
        </div>
        <div class="product-item">
          <img class="responsive-img" src="/home/bag.jpg" />
          <p v-scrollanimate="'bottom'" class="text_medium text_uppercase">
            {{ $t("home.section3.p7") }}
          </p>
        </div>
      </div>
      <ui-link-button
        class="link-button"
        text-color="white"
        :page="this.$i18n.locale + '/products'"
      >
        {{ $t("home.linkButtons.b2") }}
      </ui-link-button>
    </section>
    <section class="home-form dark t_padding_medium">
      <p class="text_medium padding_small text_center text_uppercase">
        {{ $t("home.section4.p1") }}
      </p>
      <div class="contacts-items max_with">
        <div
          class="desktop_show contacts-img"
          style="background: url('/home/contact.jpg')"
        ></div>
        <div class="contacts-form padding_medium">
          <form>
            <div class="form-desktop-row">
              <ui-text-field
                class="v_margin_big"
                name="firstName"
                ref="formField1"
                :error-message="this.$i18n.t('messages.error1')"
                :error="this.validationErrors.firstName"
                @textChanged="textInput"
              >
                {{ $t("contacts.form.i1") }}
              </ui-text-field>
              <ui-text-field
                class="v_margin_big"
                name="lastName"
                ref="formField2"
                :error-message="this.$i18n.t('messages.error1')"
                :error="this.validationErrors.lastName"
                @textChanged="textInput"
              >
                {{ $t("contacts.form.i2") }}
              </ui-text-field>
            </div>
            <ui-text-field
              class="v_margin_big"
              ref="formField3"
              name="country"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i3") }}
            </ui-text-field>
            <ui-text-field
              class="v_margin_big"
              ref="formField4"
              name="phone"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i4") }}
            </ui-text-field>
            <ui-text-field
              class="v_margin_big"
              ref="formField5"
              name="mail"
              :error-message="this.$i18n.t('messages.error1')"
              :error="this.validationErrors.mail"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i5") }}
            </ui-text-field>
            <ui-text-area
              class="v_margin_big"
              ref="formField6"
              name="message"
              @textChanged="textInput"
            >
              {{ $t("contacts.form.i6") }}
            </ui-text-area>
            <ui-button
              class="button text_small"
              :flat="false"
              bg-color="#FFBE4F"
              border-radius="5px"
              @click.prevent="sendForm"
            >
              {{ $t("contacts.form.b1") }}</ui-button
            >
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import backgroundImage from "@/assets/home/home_bg_image.jpg";
import uiTopPageSection from "../components/UI/uiTopPageSection";
import uiAnimatedTexts from "../components/UI/uiAnimatedTexts";
import uiTextField from "../components/UI/uiTextField";
import uiButton from "../components/UI/uiButton";
import uiTextArea from "../components/UI/uiTextArea";
import * as _ from "lodash";
import uiLinkButton from "../components/UI/uiLinkButton";
import uiAlert from "../components/UI/uiAlert";
import { gsap, Power2 } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "Home",
  components: {
    uiTopPageSection,
    uiAnimatedTexts,
    uiTextField,
    uiButton,
    uiTextArea,
    uiLinkButton,
    uiAlert,
  },
  data() {
    return {
      backgroundImage,
      form: {
        type: "contact",
        firstName: "",
        lastName: "",
        country: "",
        phone: "",
        mail: "",
        message: "",
      },
      validation: {
        notEmpty: ["firstName", "lastName", "mail"],
      },
      validationErrors: {},
    };
  },
  methods: {
    textInput(name, value) {
      if (_.has(this.form, name)) {
        this.form[name] = value;
      }
      if (_.has(this.validationErrors, name)) {
        this.validationErrors[name] = false;
      }
    },
    validateForm() {
      this.validationErrors = {};
      let totalErrors = 0;
      _.forEach(this.validation.notEmpty, (fieldName) => {
        if (_.isNil(this.form[fieldName]) || this.form[fieldName] == "") {
          this.validationErrors[fieldName] = true;
          totalErrors++;
        } else {
          this.validationErrors[fieldName] = false;
        }
      });
      if (totalErrors === 0) {
        return true;
      } else {
        return false;
      }
    },
    sendForm() {
      let validate = this.validateForm();
      if (validate) {
        this.$store.dispatch("sendMail", this.form);
        this.$refs.alert.show(this.$i18n.t("messages.sent"));
        this.clearForm();
      } else {
        this.$refs.alert.show(this.$i18n.t("messages.error2"), true);
      }
    },
    clearForm() {
      for (let i = 1; i <= 6; i++) {
        this.$refs[`formField${i}`].clean();
      }
    },
    scrollMeTo(refName) {
        let element = this.$refs[refName];
        let top = element.offsetTop;
        gsap.to(window, {
          duration: 0.5,
          scrollTo: top - 100,
          ease: Power2.easeOut,
        });
    },
  },
  mounted(){
    let refName = this.$route.params.video;
    if(!_.isNil(refName) && !_.isNil(this.$refs[refName])){
      this.scrollMeTo(refName)
    }
  }
};
</script>
<style lang="scss">
@import "../vars";
.videos{
  background-color: #141414;
  color: white;
  .video-wrapper{
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    z-index: 1000;
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 50px;
      background: linear-gradient(#141414, #000000);
      top: 22px;
      z-index: -10;
    }
  }
  .video-header{
    font-weight: bold;
    text-align: center;
    padding: 20px 0 20px 0;
  }
  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }

  .embed-container iframe, .embed-container object, .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.link-button {
  margin: 30px auto;
}
.home-info1 {
  background-color: #eeeeee;
}
.home-info2 {
  .section {
    position: absolute;
  }
  .img-overgroud {
    height: 125vw;
    background: linear-gradient(0deg, #141414 0%, rgba(20, 20, 20, 0) 100%);
  }
  .img-zhupan {
    background: url("/home/zhupan.jpg");
    background-position: center;
    background-size: cover;
  }
}
.home-info3 {
  padding: 100px 0;
  h2 {
    margin-bottom: 50px;
  }
  .product-item {
    position: relative;
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      color: white;
      font-size: 30px;
      font-weight: bold;
      text-shadow: -1px 0px 15px black;
      transform: translate(-50%, -50%);
      text-align: center;
      line-height: 0.8;
    }
  }
}
.contacts-form {
  .button {
    width: 100%;
    margin: auto;
  }
}

@media (min-width: $small_w) {
}
@media (min-width: $medium_w) {
  .videos{
    padding-top: 80px;
    .video-wrapper{
      &::before{
        height: 100px;
        top: 7px;
      }
    }
    .video-header{
      padding: 35px 0 35px 0;
    }
  }
  .home-info1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
      max-width: 1600px;
      text-align: center;
    }
  }
  .home-info2 {
    .img-overgroud {
      height: 50vw;
    }
  }
  .home-info3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    .product-items {
      margin: auto;
    }
  }
}
@media (min-width: $large_w) {
  .videos-wrapper{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin: auto;
    max-width: 2200px;
    align-items: center;
    justify-content: center;
  }
  .home-info1 {
    padding: 150px 45px;
  }
  .home-info2 {
    .img-overgroud {
      height: 400px;
    }
    .section {
      text-align: center;
      width: 100%;
    }
  }
  .home-info3 {
    padding-bottom: 0;
    .product-items {
      display: flex;
      img {
        &:hover {
          filter: saturate(2.2);
        }
      }
    }
  }
}
.contacts-items {
  display: flex;
  align-content: center;
  align-items: center;
  * {
    flex-grow: 1;
    flex-basis: 0;
  }
  .contacts-img {
    margin: 25px;
    border-radius: 5px;
    height: 700px;
    width: 100%;
    background-position: center;
    background-size: cover;
  }
}
</style>
