<template>
  <button v-ripple @click="click">
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
import router from "../../router";

export default {
  name: "uiLinkButton",
  props: {
    textColor: {
      type: String,
      required: false,
      default: "#000000",
    },
    page: {
      type: String,
      required: true,
    },
  },
  methods: {
    click() {
      router.push("/" + this.page);
    },
  },
};
</script>

<style scoped lang="scss">
button {
  position: relative;
  outline: none;
  text-decoration: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  height: 60px;
  width: 210px;
  background: transparent;
  opacity: 1;
  border: 2px solid rgba(235, 184, 2, 0.6);
  span {
    color: v-bind(textColor);
    font-family: "Gilroy", Helvetica, Arial, sans-serif;
    letter-spacing: 0.7px;
    font-size: 20px;
    font-weight: bold;
  }
  &:hover {
    animation: rotate 0.7s ease-in-out both;
    span {
      animation: storm 0.7s ease-in-out both;
      animation-delay: 0.06s;
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(3deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-3deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

@keyframes storm {
  0% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }

  25% {
    transform: translate3d(4px, 0, 0) translateZ(0);
  }

  50% {
    transform: translate3d(-3px, 0, 0) translateZ(0);
  }

  75% {
    transform: translate3d(2px, 0, 0) translateZ(0);
  }

  100% {
    transform: translate3d(0, 0, 0) translateZ(0);
  }
}
</style>
